.header {
    background-color: var(--color-primary-background);
    height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-items: center;
    overflow: hidden;
    @media (max-width: 1200px){ 
        grid-template-columns: 1fr;
        justify-items: flex-start;
    }
    
    &__logo {
        width: 8rem;
        height: 8rem;
        background-image: url('../../../my-portfolio-logo.png');
        background-position: center;
        background-size: cover;
        position: absolute;
        top: 8%;
        left: 5%;
    }
    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 1200px){ 
            padding-left: 10%;
        }
        & h1 {
            font-size: 8rem;
            line-height: 1;
            @media (max-width: 600px){ 
                font-size: 5rem;
            }
        }
        & h2 {
            font-size: 2.5rem;
            margin: 1rem 0;
            text-transform: capitalize;
            margin-bottom: 1.5rem;
            margin-top: 1.5rem;
            @media (max-width: 600px){ 
                font-size: 2rem;
            }
        }
        & h3 {
            font-size: 1.8rem;
            font-weight: 400;
            color: var(--grey);
            @media (max-width: 600px){ 
                font-size: 1.2rem;
            }
        }
    }
}

.grid {
    display: grid;
    grid-template-columns: 30rem 30rem;
    grid-template-rows: 24rem 24rem;
    gap: 2rem;
   @media (max-width: 1200px){ 
        display: none;
    } 
    @media (max-height: 800px){ 
        grid-template-rows: 20rem 20rem;
    } 
    @media (max-height: 650px){ 
        padding-right: 25rem;
    } 

    &__item {
        background-image: url('../../../assets/Landing1.png');
        background-position: center;
        background-size: cover;
        border-radius: 2rem;
        width: 100%;
        height: 100%;
        // border: 0.1px solid var(--color-secondary);
        &_2 {
            background-image: url('../../../assets/Landing2.png');
        }
        &_3 {
            background-image: url('../../../assets/Landing3.png');
        }
        &_4 {
            background-image: url('../../../assets/Landing4.png');
        }
    }
};
