

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap');
@import "./typography";


:root {
    --color-primary: #fff;
    --color-secondary: #ED1D24;
    --color-primary-background: #1e1e37;
    --color-secondary-background: #090621;
    --color-third-background: #0f0f28;
    --grey: #A1A1A1;

}

*,
*::after, 
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

*:focus {
    outline: none;
}

html {
    font-size: 62.5%;
    box-sizing: border-box;
    max-width: 100vw;
    scroll-behavior: smooth;
    @media (max-width: 600px){ 
        font-size: 50%;
      }

}

body {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    line-height: 1.9;
    background-color: var(--color-primary-background);
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
}


.main-title {
    color: var(--grey);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
}

.color-alt {
    color: var(--color-secondary);
}

.socials {
    display: flex;
    justify-content: space-evenly;
    & a {
        cursor: pointer;
        font-size: 3.5rem;
        color: #fff;
        border-radius: 60px;
        background-color: transparent;
        height: 4.5rem;
        width: 4.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        transition: all 0.2s ease;
        &:hover {
            background-color: var(--color-secondary);
        }
    }
}

.main-show {
    display: block;
}

.main-hide {
    display: none;
}

.main-button {
    border-radius: 30px;
    height: 50px;
    width: 100%;
    line-height: 0;
    padding-top: 28px;
    padding-bottom: 28px;
    background-color: #ED1D24;
    border: none;
    color: #fff;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
}