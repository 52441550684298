.nav__icon {
  width: 60px;
  height: 45px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  position: absolute;
  top: 10%;
  right: 10%;
  @media (max-width: 600px){ 
   transform: scale(0.6);
}
}

.nav__icon span {
  display: block;
  position: absolute;
  height: 9px;
  width: 50%;
  background: var(--color-secondary);
  opacity: 1;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

.nav__icon span:nth-child(even) {
  left: 50%;
  border-radius: 0 9px 9px 0;
}

.nav__icon span:nth-child(odd) {
  left:0px;
  border-radius: 9px 0 0 9px;
}

.nav__icon span:nth-child(1), .nav__icon span:nth-child(2) {
  top: 0px;
}

.nav__icon span:nth-child(3), .nav__icon span:nth-child(4) {
  top: 18px;
}

.nav__icon span:nth-child(5), .nav__icon span:nth-child(6) {
  top: 36px;
}

.nav__icon.open span:nth-child(1),.nav__icon.open span:nth-child(6) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.nav__icon.open span:nth-child(2),.nav__icon.open span:nth-child(5) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.nav__icon.open span:nth-child(1) {
  left: 5px;
  top: 7px;
}

.nav__icon.open span:nth-child(2) {
  left: calc(50% - 5px);
  top: 7px;
}

.nav__icon.open span:nth-child(3) {
  left: -50%;
  opacity: 0;
}

.nav__icon.open span:nth-child(4) {
  left: 100%;
  opacity: 0;
}

.nav__icon.open span:nth-child(5) {
  left: 5px;
  top: 29px;
}

.nav__icon.open span:nth-child(6) {
  left: calc(50% - 5px);
  top: 29px;
}
