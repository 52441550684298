.menu {
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr 1.5fr 2fr;
    @media (max-width: 1150px){
        grid-template-columns: 1fr 1.5fr 1fr;
    }
    @media (max-width: 950px){
        grid-template-columns: 1.5fr 1fr;
    }
    @media (max-width: 600px){
        grid-template-columns: 1fr;
    }
    
    // animation: AnimationLoadIn 0.2s linear 0s 1 normal none;
    &__filler {
        background-color: var(--color-primary-background);
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 950px){
            display: none;
        }
        &__image {
            width: 15rem;
            height: 15rem;
            background-image: url('../../my-portfolio-logo.png');
            background-position: center;
            background-size: cover;
        }

    }
    &__details {
        background-color: var(--color-secondary-background);
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        
        &__contact {
            display: flex;
            flex-direction: column;
            width: 80%;
            row-gap: 4rem;
            &__icon {
                color: var(--color-secondary);
                margin-right: 3rem;
                font-size: 2.5rem;
                display: flex;
            }
            & p {
                font-size: 2rem;
            }
            & div {
                display: flex;
                align-items: center;
            }
        }
        &__social {
            display: flex;
            justify-content: space-evenly;
            color: gray;
            width: 70%;
        }
    }
    &__options {
        background-color: var(--color-secondary-background);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        padding: 8rem 0;
        @media (max-width: 460px){
            grid-row: 1 / 2;
        }
        
        & h2 {
            transition: color 0.2s ease;
            cursor: pointer;
            font-size: 4.5rem;
        }
        & h2:hover {
            color: var(--color-secondary);

        }

    }
}

@keyframes AnimationLoadIn {
    0% {
       opacity: 0;
    }
    100% {
        opacity: 1;
    }
}