.contact {
    background-color: var(--color-secondary-background);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    text-align: center;
    gap: 6rem;
    padding: 0 15%;
    @media (max-width: 850px){
        padding: 15% 15%;
    }
}

.column {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.avatar {
    background-image: url('../../../tj-portfolio.jpg');
    background-position: center;
    background-size: cover;
    border-radius: 100%;
    width: 7rem;
    height: 7rem;
}
