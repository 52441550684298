h1, h2, h4, h3, h5, p, small {
    font-family: inherit;
    color: #fff;
}

p {
    line-height: 1.5;
}

h2 {
    font-size: 3rem;
    font-weight: 400;
    line-height: 1.5;
}

h3 {
    font-size: 2.5rem;
    font-weight: 200;
}

h4 {
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 1.2;
}

h5 {
    color: var(--grey);
    font-family: 'Montserrat', sans-serif;
    font-size: 1.6rem;
    letter-spacing: 0.4rem;
    text-transform: uppercase;
    // font-weight: 400;
}


p {
    font-size: 1.8rem;
}

a {
    color: var(--color-secondary);
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;
    text-decoration: none;
}

small {
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--grey);
}




